import { Inject, Injectable, isDevMode } from '@angular/core';
import { RxapAuthenticationService } from '@rxap/authentication';
import { NavigationItem, NavigationStatus } from '@rxap/layout';
import { KeycloakService } from '@rxap/keycloak';
import { EurogardAuthenticationService } from './eurogard-authentication.service';

export interface EurogardNavigationItem extends NavigationItem {
  permissions?: string[];
}

@Injectable({providedIn: 'root'})
export class NavigationStatusService implements NavigationStatus {

  constructor(
    @Inject(KeycloakService)
    private readonly keycloakService: KeycloakService,
    @Inject(RxapAuthenticationService)
    private readonly authenticationService: EurogardAuthenticationService,
  ) {
  }

  isVisible(navigationItem: EurogardNavigationItem): boolean {
    const roles = this.keycloakService.getUserRoles();
    if (navigationItem.permissions?.length) {
      const hasPermission = navigationItem.permissions.some(permission => roles.includes(permission));
      if (isDevMode()) {
        console.warn('NavigationStatusService :: User does not have the required permissions');
      }
      return hasPermission;
    }

    return true;
  }


}
