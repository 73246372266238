import { MethodWithParameters } from '@rxap/pattern';
import { ExternalApp } from '@rxap/layout';
import { inject, Injectable } from '@angular/core';
import { AuthorizationService } from '@rxap/authorization';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class FilterExternalAppsWithPermissionsMethod implements MethodWithParameters<ExternalApp[], ExternalApp[]> {

  private readonly authorizationService = inject(AuthorizationService);

  async call(externalApps: ExternalApp[]): Promise<ExternalApp[]> {
    const filteredAppList: Array<ExternalApp> = [];
    for (const app of externalApps) {
      if (!app.permissions?.length || await firstValueFrom(this.authorizationService.hasPermission$(app.permissions))) {
        filteredAppList.push(app);
      }
    }
    return filteredAppList;
  }

}
