import { Injectable } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { UserSettingsControllerSetRequestBody } from '../request-bodies/user-settings-controller-set.request-body';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: 'service-user-settings',
    operationId: 'UserSettingsController_set',
    operation: '{"operationId":"UserSettingsController_set","parameters":[],"requestBody":{"required":true,"content":{"application/json":{"schema":{"type":"object","properties":{"favoriteDashboards":{"type":"array","items":{"type":"string"}},"darkMode":{"type":"boolean"},"favoriteMachines":{"type":"array","items":{"type":"string"}},"hideRouter":{"type":"boolean"},"changelogLastVersion":{"type":"string","nullable":true}},"required":["favoriteDashboards","darkMode","favoriteMachines"]}}}},"responses":{"201":{}},"method":"post","path":"/user"}'
  })
export class UserSettingsControllerSetRemoteMethod extends OpenApiRemoteMethod<void, void, UserSettingsControllerSetRequestBody> {
  public override call(parameters: OpenApiRemoteMethodParameter<void, UserSettingsControllerSetRequestBody>): Promise<void> {
    return super.call(parameters);
  }
}
