import { Observable } from 'rxjs';
import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { CompanySelectService } from 'angular-services/company-select.service';
import { inject } from '@angular/core';

export function CompanySelectInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {

  const companySelectService = inject(CompanySelectService);

  if (companySelectService.companyId) {
    if (!request.url.match(/\/company-controller\/getAll/)) {
      request = request.clone({
        setHeaders: {
          Company: companySelectService.companyId,
        },
        setParams: {
          company: companySelectService.companyId,
        },
      });
    }
  }
  return next(request);

}
