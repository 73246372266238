import {
  ApplicationRef,
  createComponent,
  inject,
  Injectable,
} from '@angular/core';
import { RXAP_ENVIRONMENT } from '@rxap/environment';
import { take } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ChangelogDialogComponent } from '@rxap/ngx-changelog';
import {
  UserSettingsControllerSetRemoteMethod
} from 'open-api-service-user-settings/remote-methods/user-settings-controller-set.remote-method';
import {
  UserSettingsControllerGetByUserIdRemoteMethod
} from 'open-api-service-user-settings/remote-methods/user-settings-controller-get-by-user-id.remote-method';

export const RXAP_CHANGELOG_LAST_VERSION = 'RXAP_CHANGELOG_LAST_VERSION';
export const RXAP_CHANGELOG_DISABLED = 'RXAP_CHANGELOG_DISABLED';

@Injectable({
  providedIn: 'root',
})
export class EurogardChangelogService {

  private readonly version = inject(RXAP_ENVIRONMENT).name;
  private readonly userSettingsControllerGetByUserIdRemoteMethod = inject(UserSettingsControllerGetByUserIdRemoteMethod);
  private readonly userSettingsControllerSetRemoteMethod = inject(UserSettingsControllerSetRemoteMethod);

  private readonly applicationRef = inject(ApplicationRef);

  private lastVersion: string | null = null;

  public showChangelogDialog() {
    const body = document.getElementsByTagName('body')[0];
    const div = document.createElement('div');
    body.appendChild(div);
    const componentRef = createComponent(ChangelogDialogComponent, {
      hostElement: div,
      environmentInjector: this.applicationRef.injector,
    });
    this.applicationRef.attachView(componentRef.hostView);
    componentRef.instance.closeDialog.pipe(
      take(1),
      tap(remember => {
        componentRef.destroy();
        body.removeChild(div);
        div.remove();
        if (remember && this.version) {
          this.setLastVersion(this.version);
        } else {
          this.setLastVersion(null);
        }
      }),
    ).subscribe();
  }

  public get isDisabled(): boolean {
    return localStorage.getItem(RXAP_CHANGELOG_DISABLED) === 'true';
  }

  async getLastversion() {
    return (await this.userSettingsControllerGetByUserIdRemoteMethod.call()).changelogLastVersion ?? null;
  }

  setLastVersion(version: string | null) {
    this.lastVersion = version;
    this.userSettingsControllerSetRemoteMethod.call({
      requestBody: {
        favoriteDashboards: [],
        darkMode: true,
        favoriteMachines: [],
        changelogLastVersion: version
      }
    });
  }

  public async showChangelogDialogIfNewVersion() {

    if (this.isDisabled) {
      return;
    }

    this.lastVersion = await this.getLastversion();

    if (this.lastVersion === this.version) {
      return;
    }

    this.showChangelogDialog();

  }

}
