import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ConfigService } from '@rxap/config';
import { Title } from '@angular/platform-browser';
import { StatusIndicatorComponent } from '@rxap/ngx-status-check';

@Component({
  selector: 'eurogard-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,

  standalone: true,
  imports: [ RouterOutlet, StatusIndicatorComponent ],
})
export class AppComponent {

  constructor(
    @Inject(LOCALE_ID) localId: string,
    config: ConfigService<any>,
    titleService: Title,
  ) {
    console.log('local', localId);
    if (config.get('app.name', null)) {
      titleService.setTitle(config.getOrThrow('app.name'));
    }
  }

}
