import { Provider } from '@angular/core';
import { SOCKET_CONFIG_TOKEN, SocketIoConfig, WrappedSocket } from '@rxap/socket-io';
import { ConfigService } from '@rxap/config';

export function SocketFactory(config: SocketIoConfig) {
  return new WrappedSocket(config);
}

export function SocketConfigFactory(config: ConfigService) {
  return {
    url: config.get('socket.url'),
    options: {autoConnect: true},
  };
}

export function ProvideSocket(): Provider[] {
  return [
    {
      provide: SOCKET_CONFIG_TOKEN,
      useFactory: SocketConfigFactory,
      deps: [ ConfigService ],
    },
    {
      provide: WrappedSocket,
      useFactory: SocketFactory,
      deps: [ SOCKET_CONFIG_TOKEN ],
    },
  ];
}
