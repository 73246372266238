import { Injectable } from '@angular/core';
import { OpenApiRemoteMethod, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { UserControllerMeResponse } from '../responses/user-controller-me.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: 'legacy',
    operationId: 'user-controller-me',
    operation: '{"operationId":"user-controller-me","responses":{"200":{"content":{"application/json":{"schema":{"type":"object","properties":{"uuid":{"type":"string","format":"uuid"},"username":{"type":"string"},"activationKey":{"type":"string"},"activationKeyCreationTime":{"type":"integer","format":"int64"},"activated":{"type":"boolean"},"enabled":{"type":"boolean"},"roles":{"type":"array","items":{"$ref":"#/components/schemas/Role"}},"firebaseRegistrationToken":{"type":"string"},"firebaseWebAppMessageToken":{"type":"string"},"receiveMessages":{"type":"boolean"},"favouriteLayout":{"$ref":"#/components/schemas/Layout"},"privacyAccepted":{"type":"boolean"},"privacyAcceptedTimestamp":{"type":"integer","format":"int64"},"desktopDesignMode":{"type":"string","enum":[]},"mobileDesignMode":{"type":"string","enum":[]},"userLanguage":{"type":"string","enum":[]},"receiveEmailMessages":{"type":"boolean"},"notificationChannel":{"type":"integer","format":"int64"},"admin":{"type":"boolean"},"primaryRole":{"$ref":"#/components/schemas/Role"},"removedAt":{"type":"integer","format":"int64"},"__updatedBy":{"type":"string"},"__updatedAt":{"type":"integer","format":"int64"},"__removedAt":{"type":"integer","format":"int64"},"__archived":{"type":"boolean"},"deletedAt":{"type":"integer","format":"int64"},"updatedAt":{"type":"integer","format":"int64"}}}}}}},"method":"get","path":"/user-controller/me"}'
  })
export class UserControllerMeRemoteMethod extends OpenApiRemoteMethod<UserControllerMeResponse, void, void> {
  public override call(): Promise<UserControllerMeResponse> {
    return super.call();
  }
}
